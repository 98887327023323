import Vue from 'vue'
import App from './App.vue'
import "tailwindcss/tailwind.css"
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = 'https://bs-api.eu/radiology/'

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
