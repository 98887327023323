<template>
  <v-app>
    <!-- <v-navigation-drawer app> -->
      <HeaderMobile class="md:hidden"/>
    <!-- </v-navigation-drawer> --> 

    <!-- >>>>768px width -->
    <Header  class="hidden md:flex" /> 

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container>
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>

  </v-main>

  <v-footer padless>
    <Footer/>
  </v-footer>

  </v-app>
</template>

<script>
import Header from '@/components/Header.vue';
import HeaderMobile from '@/components/HeaderMobile.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    HeaderMobile,
    Footer
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
//@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css");

@font-face {
  font-family: "Arial";
 // src: url("./assets/fonts/University_Cyrillic.ttf");
}

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;  
}
 
</style>