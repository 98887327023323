<template>

<div @click.stop="drawer = !drawer"
    
    style="position: relative;"

  >
  <v-container>
      <v-row align="center" justify="center">
        <div class="flex flex-row justify-between">
          
          <div style="background-color: #333366;"
              class="flex flex-row justify-start">
            <v-img
              src="@/assets/logoen.svg"
              width="480"
              height="120"
            />
          <v-icon x-large style="color:#87b954" >mdi-menu</v-icon>
          </div>
            
        
        </div>
      </v-row>
    </v-container>

  <v-navigation-drawer app v-model="drawer" temporary right>
    <v-list-item class="my-4" v-for="link in links" :key="link.text">
      <v-btn elevation="6" block outlined large @click.stop="drawer=false;">
        <v-icon color="grey lighten-1">{{link.icon}}</v-icon>
        <router-link :to=link.route><span class="text-gray-900">{{link.text}}</span></router-link>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-img
        src="@/assets/logoen.svg"
        width="480"
        height="120"
    />
  </v-navigation-drawer>
    
    <!-- <v-navigation-drawer app v-model="drawer" temporary right>
      <div>
        <nav>
          <ul class="text-xl my-6 grid grid-cols-1 gap-y-12">
            <li>
              <router-link to="/archive">Latest content</router-link>
            </li>
            <li>
              <router-link to="/archive">Current issue</router-link>
            </li>
            <li>
              <router-link to="/archive">Archive</router-link>
            </li>
            <li>
              <router-link to="/authors">Authors</router-link>
            </li>
            <li>
              <router-link to="/about">About</router-link>
            </li>
          </ul>
        </nav> 
      </div> 

    <v-divider></v-divider>

     
    </v-navigation-drawer> -->
  </div>
  </template>

<script>
  export default {
    data: () => ({
      drawer: null,    
      links: [
        { icon: 'md-i-format-list-bulleted-square', text: 'Latest content', route: '/' },
        { icon: 'md-i-gesture-tap-button', text: 'About', route: '/about' },
        { icon: 'md-i-gesture-tap-button', text: 'Editors', route: '/editors' },
        // { icon: 'mdi-gesture-tap-button', text: 'Current issue', route: '/archive' },
        { icon: 'md-i-gesture-tap-button', text: 'Archive', route: '/archive' },
        { icon: 'md-i-gesture-tap-button', text: 'Instructions', route: '/instructions' },
        { icon: 'md-i-gesture-tap-button', text: 'Reviewers', route: '/reviewers' },
        { icon: 'md-i-gesture-tap-button', text: 'Contacts', route: '/contacts' },
        // { icon: 'mdi-gesture-tap-button', text: 'Login', route: '/login' },
      ]
    }),

    methods: {
     
    },
  }
</script>

<style lang="scss" scoped>

</style>