import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '@/views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    //component: Home
    
  },
  {
    path: '/archive',
    name: 'Archive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Archive/Archive.vue')
  },
  {
    path: '/editors',
    name: 'Editors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Editors.vue')
  },
  {
    path: '/reviewers',
    name: 'Reviewers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Reviewers.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Contacts.vue')
  },
  {
    path: '/archive/article',
    name: 'ArchivedArticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Archive/ArchivedArticle.vue')
  },
  {
    path: '/authors',
    name: 'Authors',
    meta: {
            requiresAuth: true,
            is_admin: true
          },    
    component: () => import('@/views/AddArticle.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => { return import ('@/views/LogIn.vue') }
  },
  {
    path: '/submitarticle',
    name: 'SubmitArticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => { return import ('@/views/SubmitArticle.vue') }
  },
  {
    path: '/instructions',
    name: 'InstructionsAuthors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => { return import ('@/views/InstructionsAuthors.vue') }
  },
  {
    path: '/eboard',
    name: 'EditorialBoard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => { return import ('@/views/EditorialBoard.vue') }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.idToken) {
          next({
          path: '/login',
          query: { redirect: to.fullPath }
          })
      } else {
          next()
      }
      } else {
      next() // make sure to always call next()!
  }
})