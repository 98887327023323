<template>
  <div>
    <div class="grid grid-cols-8 w-full" style="background-color: #333366;">
      <div class="col-span-6">
        <v-img
          class="-ml-12 mt-2"
          src="@/assets/logoen.svg"
          width="400"
          height="80"
        />
      </div>
      <div class="col-span-2 my-auto flex">
        <v-text-field
          color="#87b954"
          append-outer-icon="mdi-send"
          clear-icon="mdi-close-circle"
          clearable label="Search"
          style="width:245px;"
          dense
          background-color="blue lighten-5"
          @click:append-outer="sendMessage"
          @click:clear="clearMessage">
        </v-text-field>
      </div>
      <div  class="col-span-7">
        <nav>
          <ul>
            <li>
              <router-link to="/about" style="color:#87b954">About</router-link>
            </li>
            <li>
              <router-link to="/editors" style="color:#87b954">Editors</router-link>
            </li>
            <li >
              <router-link to="/" style="color:#87b954">Latest content</router-link>
            </li>
            <li>
              <router-link to="/archive" style="color:#87b954">Current Issue content</router-link>
            </li>
            <li>
              <router-link to="/archive" style="color:#87b954">Archive</router-link>
            </li>
            <li>
              <router-link to="/instructions" style="color:#87b954">Instructions for authors</router-link>
            </li>
            <li>
              <router-link to="/authors" style="color:#87b954">Add</router-link>
            </li>
            <li>
              <router-link to="/reviewers" style="color:#87b954">Reviewers</router-link>
            </li>
            <li>
              <router-link to="/contacts" style="color:#87b954">Contacts</router-link>
            </li>
<!--            <li>-->
<!--              <router-link style="color:#87b954" to="/login">Log In</router-link>-->
<!--            </li>-->
            <li>
              <v-btn v-if="this.$store.state.idToken" style="color:#87b954" @click="logout();">Log Out</v-btn>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data: () => ({
      password: 'Password',
      show: false,
      message: 'Hey!',
      marker: true,
      iconIndex: 0,
      icons: [
        'mdi-emoticon',
        'mdi-emoticon-cool',
        'mdi-emoticon-dead',
        'mdi-emoticon-excited',
        'mdi-emoticon-happy',
        'mdi-emoticon-neutral',
        'mdi-emoticon-sad',
        'mdi-emoticon-tongue',
      ],
      links: [
        { icon: 'home', text: 'Home', route: '/' },
        { icon: 'folder', text: 'My Projects', route: '/projects' },
        { icon: 'person', text: 'Team', route: '/team' },
      ]
    }),

    methods: {
      toggleMarker () {
        this.marker = !this.marker
      },
      sendMessage () {
        this.resetIcon()
        this.clearMessage()
      },
      clearMessage () {
        this.message = ''
      },
      resetIcon () {
        this.iconIndex = 0
      },
      changeIcon () {
        this.iconIndex === this.icons.length - 1
          ? this.iconIndex = 0
          : this.iconIndex++
      },
      logout(){        
        this.$store.dispatch('logout')
         .then( () => { 
           this.$router.push('/')
           }, function() {})        
      }
    },
  }
</script>

<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

nav ul {
  text-align: center;
  color: white; 
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 5px;
  font-family: "Open Sans";
  //text-transform:uppercase;
  color: white;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

nav ul li a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.1);
  color: white;
}
</style>